window['sap-ui-config'] = {}
// determine the proper theme for UI5 from current color scheme
window['sap-ui-config'].theme = (function () {
  try {
    const state = localStorage.getItem('state.key_-oMQDlug5EfvVaKq2JiZ')
    if (state) {
      const theme = JSON.parse(JSON.parse(state))?.theme
      return !theme ? 'sap_fiori_3' : theme
    }
    return 'sap_fiori_3'
  } catch (ex) {
    // eslint-disable-next-line no-console
    console.error('Error while reading localStorage', ex)
    // eslint-disable-next-line no-console
    console.warn('window.matchMedia not supported - keep default theme')
    // eslint-disable-next-line no-console
    console.warn('window.localStorage not supported - keep default theme')
    return 'sap_fiori_3'
  }
})()
